import { Button, Input, Spacer } from "@nextui-org/react";
import { FormEvent, useState } from "react";

export type Field = {
  name: string;
  placeholder?: string;
  inline?: boolean;
  disabled?: boolean;
  required?: boolean;
  type?: string;
} & Record<string, unknown>;

export type FormValues = Record<string, string>;

export default function Form({
  fields,
  opts,
  submitFunc,
}: {
  fields: Field[];
  opts?: { initialValues?: FormValues; submitLabel?: string };
  submitFunc: (formValues: FormValues) => Promise<void>;
}) {
  const [formState, setFormState] = useState<FormValues>(
    opts?.initialValues || {}
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  async function doSubmit(e: FormEvent) {
    e?.preventDefault();
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);
    setErrMsg(null);

    try {
      await submitFunc(formState);
    } catch (e) {
      setErrMsg(`${e as string}`);
    }

    setIsSubmitting(false);
    return false;
  }

  return (
    <form
      className="max-w-lg mx-auto flex flex-col"
      onSubmit={(e) => doSubmit(e)}
    >
      <Spacer />

      {fields.map(({ inline, name, placeholder, ...field }, idx) => (
        <div key={name}>
          {idx > 0 ? <Spacer y={1} /> : null}
          <Input
            bordered
            shadow={false}
            {...field}
            placeholder={inline ? placeholder : ""}
            labelPlaceholder={!inline ? placeholder || name : ""}
            value={formState[name] || ""}
            onChange={(e) =>
              setFormState((prevState) => ({
                ...prevState,
                [name]: e.target.value,
              }))
            }
            type={field.type || "string"}
          />
        </div>
      ))}

      <Spacer y={1.5} />

      {isSubmitting ? (
        <p className="animate-bounce">Sending...</p>
      ) : (
        <Button type="submit" disabled={isSubmitting}>
          {opts?.submitLabel || "Save"}
        </Button>
      )}

      {errMsg ? <p className="block text-red-500 pt-4">{errMsg}</p> : null}
    </form>
  );
}
