import { useEffect, useState } from "react";
import { getMockData } from "./mock-supabase-client";
import getSupabaseClient, { getAuthSession, IS_OFFLINE } from "./supabase";

export type Account = {
  activeSubscription: boolean;
  createdAt: string;
  id: number;
  name: string;
  primaryAdminEmail?: string;
  slug: string;
  userRoles: Record<string, string>;
};

// define some funcs to remember account state through refreshes
export function getCurrentAccount(): Account | null {
  if (IS_OFFLINE) {
    return getMockData().accounts[0] || null;
  }

  try {
    const raw = localStorage.getItem("currentAccount");
    return {
      userRoles: {},
      ...JSON.parse(raw as string),
    } as Account;
  } catch (e) {
    return null;
  }
}
export function setCurrentAccount(account: Account): void {
  localStorage.setItem("currentAccount", JSON.stringify(account));
}

export function useCurrentAccount(): Account | null {
  const [lastChecked, setLastChecked] = useState<number>(0);

  // @NOTE: we're just being lazy here... sharing state between hooks sucks, so we're doing this polling check for now
  useEffect(() => {
    let curId = getCurrentAccount()?.id;

    const interval = setInterval(() => {
      let newId = getCurrentAccount()?.id;
      if (newId !== curId) {
        curId = newId;
        setLastChecked(Date.now());
      }
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return getCurrentAccount();
}

export async function refreshCurrentAccount(): Promise<void> {
  const currentAccount = getCurrentAccount();
  if (!currentAccount) {
    return;
  }

  const accounts = await getAccounts(currentAccount.id);
  const newCurrentAccount = accounts.find((a) => a.id === currentAccount.id);
  if (!newCurrentAccount) {
    return;
  }

  setCurrentAccount(newCurrentAccount);
}

export async function getAccounts(accountId?: number): Promise<Account[]> {
  const { userId } = getAuthSession();

  let { data: accounts } = await getSupabaseClient()
    .from("accounts")
    .select("*")
    .contains("user_ids", [userId]);

  if (accountId) {
    accounts = accounts?.filter((a) => a.id === accountId) || [];
  }

  const resps = await Promise.all(
    (accounts || []).map((a) =>
      fetch(`/api/get-account-users?accountId=${a?.id}`)
        .then((res) => res.json())
        .then(({ users }) => ({
          id: a.id,
          users: users as { id: string; email: string }[],
        }))
    )
  );

  return (accounts || []).map((a) => ({
    activeSubscription: a.active_subscription === true,
    createdAt: a.created_at,
    id: a.id,
    name: a.name,
    primaryAdminEmail: resps
      .find((r) => r.id === a.id)
      ?.users?.find((u) => u.id === a.user_ids[0])?.email,
    slug: a.slug,
    userRoles: a.user_roles || {},
  }));
}
