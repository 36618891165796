import { getCurrentAccount } from "./accounts";
import getSupabaseClient from "./supabase";

export type Source = {
  accountId: number;
  config: {
    endpointHash?: string;
    stripeId?: string;
  } & Record<string, string>;
  id: number;
  name: string;
  sourceType: string;
  tags: string[];
};

export async function getSources(
  {
    accountId,
    sourceType,
  }: {
    accountId?: string;
    sourceType?: string[];
  },
  fromServer?: boolean
): Promise<Source[]> {
  const account = getCurrentAccount();
  if (!account && !fromServer) {
    return [];
  }

  let query = getSupabaseClient(true)
    .from("sources")
    .select("*")
    .is("archived_at", null);

  if (account) {
    query = query.eq("account_id", account.id);
  } else if (accountId && fromServer) {
    query = query.eq("account_id", accountId);
  }
  if (sourceType) {
    query = query.in("source_type", sourceType);
  }

  const { data: sources } = await query;

  return (sources || [])
    .map((s) => ({
      accountId: s.account_id,
      config: s.config,
      id: s.id,
      name: s.name,
      sourceType: s.source_type,
      tags: s.tags?.split(",") || [],
    }))
    .concat(
      sourceType?.includes("invalid")
        ? [
            {
              name: "No Lead Source",
              sourceType: "invalid",
              id: -1,
              accountId,
              tags: [],
              config: {},
            },
          ]
        : []
    );
}

export async function addSource({
  accountId,
  config,
  name,
  sourceType,
  tags,
}: Partial<Source>): Promise<number> {
  if (!accountId) {
    const account = getCurrentAccount();
    if (!account) {
      throw new Error("no current account");
    }
    accountId = account.id;
  }

  const { data, error } = await getSupabaseClient(true)
    .from("sources")
    .insert({
      account_id: accountId,
      config: config || {},
      name,
      source_type: sourceType,
      tags: tags?.join(",") || "",
    })
    .select("id");

  if (error) {
    throw error;
  }

  return data.shift()?.id;
}

export async function updateSource(
  id: number,
  { config, name, tags }: Partial<Source>
): Promise<number> {
  const { data, error } = await getSupabaseClient(false)
    .from("sources")
    .update({
      config: config || {},
      name,
      tags: tags?.join(",") || "",
    })
    .eq("id", id)
    .select("id");

  if (error) {
    throw error;
  }

  return data.shift()?.id;
}
