import getSupabaseClient from "./supabase";

export type Transaction = {
  amountCents: number;
  email: string;
  extra?: Record<string, unknown>;
  id: number;
  sourceId: number;
  transactedAt: string;
};

export async function getTransactionsForSources(
  sourceIds: number[],
  fromServer = false
): Promise<Record<number, Transaction[]>> {
  const paginatedSets: Transaction[][] = [];
  let hasMore = true;

  while (hasMore) {
    let query = getSupabaseClient(fromServer)
      .from("transactions")
      .select("*")
      .in("source_id", sourceIds);

    const lastSet = paginatedSets.slice().pop();
    if (lastSet && lastSet.length) {
      query = query.gt("id", lastSet[lastSet.length - 1].id);
    }

    const { data: transactions } = await query;

    const parsedTransactions: Transaction[] = (transactions || []).map((t) => ({
      amountCents: t.amount_cents,
      email: t.email,
      extra: t.extra || {},
      id: t.id,
      sourceId: t.source_id,
      transactedAt: t.transacted_at,
    }));

    paginatedSets.push(parsedTransactions);

    hasMore = parsedTransactions.length === 10000 && paginatedSets.length < 10;
  }

  return paginatedSets
    .reduce((a, b) => a.concat(b), [])
    .reduce(
      (a: Record<number, Transaction[]>, b: Transaction) => ({
        ...a,
        [b.sourceId]: [...(a[b.sourceId] || []), b],
      }),
      {}
    );
}

export async function addTransactions(
  transactions: Omit<Transaction, "id">[],
  fromServer = false
): Promise<number[]> {
  const allIds: number[] = [];

  for (let i = 0; i < transactions.length; i += 9999) {
    const { data, error } = await getSupabaseClient(fromServer)
      .from("transactions")
      .insert(
        transactions.slice(i, i + 999).map((tx) => ({
          amount_cents: tx.amountCents,
          email: tx.email,
          extra: tx.extra || {},
          source_id: tx.sourceId,
          transacted_at: tx.transactedAt,
        }))
      )
      .select("id");

    if (error) {
      console.error(error);
      return [];
    }

    if (!data || !data.length || !data[0].id) {
      return [];
    }

    allIds.push(...data.map((d) => d.id));
  }

  return allIds;
}
