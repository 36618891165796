import { useEffect, useState } from "react";
import { getLeadsForSources, Lead } from "./leads";
import { getSources, Source } from "./sources";
import { getTransactionsForSources, Transaction } from "./transactions";

const cache: {
  loading: boolean;
  sources: Source[];
  leadsBySource: Record<number, Lead[]>;
  transactionsBySource: Record<number, Transaction[]>;
} = {
  loading: false,
  sources: [],
  leadsBySource: {},
  transactionsBySource: {},
};

const cacheSubs: Record<string, Function> = {};

export async function doLookups() {
  if (cache.loading) {
    return;
  }

  cache.loading = true;

  cache.sources = await getSources({
    // @TODO: enum this / make it a constant somewhere
    sourceType: ["csv", "endpoint", "stripe", "invalid"],
  });

  const leadsPromise = getLeadsForSources(
    cache.sources
      .filter((s) => ["csv", "endpoint"].includes(s.sourceType))
      .map((s) => s.id)
  ).then((curLeads) => {
    const allLeads = Object.values(curLeads).reduce((a, b) => a.concat(b), []);
    allLeads.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
    );

    cache.leadsBySource = curLeads;
  });

  const transactionsPromise = getTransactionsForSources(
    cache.sources
      .filter((s) => ["stripe"].includes(s.sourceType))
      .map((s) => s.id)
  ).then((curTransactions) => {
    cache.transactionsBySource = curTransactions;
  });

  await Promise.all([leadsPromise, transactionsPromise]);

  cache.loading = false;

  Object.values(cacheSubs).forEach((sub) => sub(cache));
}

export default function useAccountData(): typeof cache {
  const [localId] = useState<string>(`local-id-${Math.random()}`);
  const [localCache, setLocalCache] = useState<typeof cache>(cache);

  useEffect(() => {
    cacheSubs[localId] = setLocalCache;

    return () => {
      delete cacheSubs[localId];
    };
  }, [localId]);

  return localCache;
}
