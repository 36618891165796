import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-toastify/dist/ReactToastify.css";
import hmac from "crypto-js/hmac-sha256";
import type { AppProps } from "next/app";
import { NextUIProvider, Loading, Text, Spacer } from "@nextui-org/react";
import { ToastContainer } from "react-toastify";

import "../styles/globals.css";
import Head from "next/head";
import Login from "../components/login";
import Nav from "../components/nav";
import useAccountData, { doLookups } from "../lib/use-account-data";
import { useAuthSession } from "../lib/supabase";
import { refreshCurrentAccount, useCurrentAccount } from "../lib/accounts";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

function App({ Component, pageProps }: AppProps) {
  const { email, name, userId } = useAuthSession();
  const account = useCurrentAccount();
  const { loading } = useAccountData();
  const [, setLoadedAt] = useState<number>(0);
  const router = useRouter();

  useEffect(() => {
    refreshCurrentAccount();
  }, []);

  useEffect(() => {
    if (account?.id && email?.length) {
      setTimeout(() => {
        (
          window as unknown as Record<
            string,
            Record<string, (args: any, cb: typeof console.error) => void>
          >
        ).Tawk_API.setAttributes(
          {
            company: account?.name,
            companyId: account.id,
            email,
            name,
            // @TODO: move this server side
            hash: hmac(
              email,
              "14ac56e500382e528dd2827cb8a7719b9b439c29"
            ).toString(),
          },
          console.error
        );
      }, 3000);

      doLookups().then(() => {
        setLoadedAt(Date.now());
      });
    }
  }, [account?.id, , account?.name, email, name]);

  useEffect(() => {
    (window as unknown as Record<string, unknown>).Tawk_API =
      (window as unknown as Record<string, unknown>).Tawk_API || {};
    (window as unknown as Record<string, unknown>).Tawk_LoadStart = new Date();

    (function () {
      const s1 = document.createElement("script");
      s1.async = true;
      s1.src = "https://embed.tawk.to/63c9f53147425128790ea78c/1gn6ebib7";
      s1.setAttribute("crossorigin", "*");
      document.body.appendChild(s1);
    })();
  }, []);

  useEffect(() => {
    if (email?.length && router.asPath.startsWith("/login")) {
      router.push("/customer-analysis");
    }
  }, [router, email]);

  const isViewerPath = [
    "/lead-analysis",
    "/customer-analysis",
    "/accounts",
  ].find((v) => router.asPath.startsWith(v));
  const curAuthRole = account?.userRoles[userId as string] || "admin";

  useEffect(() => {
    if (curAuthRole === "viewer" && !isViewerPath) {
      router.push("/lead-analysis");
    }
  }, [curAuthRole, isViewerPath, router]);

  const isNonLoginPublicPath = ["/register", "/reset"].find((v) =>
    router.asPath.startsWith(v)
  );

  const mobileOnly = (
    <div className="lg:hidden flex min-h-screen flex-col justify-center align-middle text-center">
      <p className="text-xl p-8">
        This site is not yet optimized for mobile experiences.
      </p>
      <Spacer />
      <a
        href="https://leadinsightpro.com"
        className="underline text-blue-500 text-xl"
      >
        Go Home
      </a>
    </div>
  );

  if (!email && !isNonLoginPublicPath) {
    return (
      <NextUIProvider>
        {mobileOnly}
        <div className="hidden lg:block container mx-auto my-10 px-4 pt-10">
          <Login />
        </div>
      </NextUIProvider>
    );
  }

  if (loading) {
    return (
      <NextUIProvider>
        <div className="fixed left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-center">
          <Text h1 size={36}>
            Loading account data
          </Text>
          <Loading size="xl" />
        </div>
      </NextUIProvider>
    );
  }

  const isNewAccount =
    account?.createdAt &&
    (new Date(account.createdAt).getTime() || 0) >
      Date.now() - 7 * 24 * 60 * 60 * 1000;

  return (
    <NextUIProvider>
      {mobileOnly}
      <div className="hidden lg:flex min-h-screen flex-row">
        <Head>
          <title>Lead Insight Pro</title>
        </Head>

        {!isNonLoginPublicPath ? <Nav /> : null}

        <div
          className={`container mx-auto page-container ${
            isNewAccount ? "mt-9" : ""
          }`}
        >
          <div className="container mx-auto my-8 px-4 pb-16">
            <Component {...pageProps} />
          </div>
        </div>

        {!isNonLoginPublicPath && isNewAccount ? (
          <div
            className="fixed top-0 left-0 w-full bg-yellow-400 text-center p-1"
            style={{ zIndex: 999 }}
          >
            View the quick start guide & get set up in 5 minutes
            <button
              className="ml-4 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://leadinsightpro.tawk.help/article/getting-started",
                  "_blank"
                )
              }
            >
              View the guide
            </button>
          </div>
        ) : null}

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </NextUIProvider>
  );
}

export default App;
