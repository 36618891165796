import { createClient, Session, SupabaseClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import getMockSupabaseClient from "./mock-supabase-client";

export const IS_OFFLINE = process.env.NEXT_PUBLIC_IS_OFFLINE === "true";

function wrappedCreateClient(clientKey: string): SupabaseClient {
  if (IS_OFFLINE) {
    return getMockSupabaseClient();
  }

  return createClient("https://ovseerdyuwiexssbdiaw.supabase.co", clientKey);
}

const clients: {
  service?: SupabaseClient;
  user?: SupabaseClient;
} = {};

export default function getSupabaseClient(
  asServer: boolean = false
): SupabaseClient {
  const key = asServer ? "service" : "user";

  if (!clients[key]) {
    if (key === "service" && clients.user) {
      return clients.user;
    }

    const clientKey =
      key === "service"
        ? (process.env.SUPABASE_SERVICE_KEY as string)
        : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im92c2VlcmR5dXdpZXhzc2JkaWF3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjMwMzE0MzEsImV4cCI6MTk3ODYwNzQzMX0.dIdUldgmpAwulNTCpCAPgij6ImmZN6s0xRWTlcPhwZ0";

    clients[key] = wrappedCreateClient(clientKey);
  }

  return clients[key] as SupabaseClient;
}

async function checkSession(nestedCheck?: boolean): Promise<Session | null> {
  let curSession = getSupabaseClient().auth.session();

  // @NOTE: this is a hack to handle the delay supabase needs to establish the session after redirect
  if (!curSession && !nestedCheck) {
    curSession = await new Promise((res) => {
      setTimeout(() => checkSession(true).then(res), 1000);
    });
  }

  return curSession?.user ? curSession : null;
}

export function getAuthSession(): {
  email?: string;
  name?: string;
  userId?: string;
} {
  const session = getSupabaseClient().auth.session();

  if (session) {
    return {
      email: session.user?.email,
      name: session.user?.user_metadata?.name,
      userId: session.user?.id,
    };
  }

  return {};
}

export function useAuthSession(): {
  email?: string;
  name?: string;
  userId?: string;
} {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    checkSession().then(setSession);
  }, []);

  if (session) {
    return {
      email: session.user?.email,
      name: session.user?.user_metadata?.name,
      userId: session.user?.id,
    };
  }

  return {};
}
