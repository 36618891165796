import { SupabaseClient } from "@supabase/supabase-js";

import { Account } from "./accounts";
import { Lead } from "./leads";
import { Source } from "./sources";
import { Transaction } from "./transactions";

type QueryResult = {
  data: Record<string, string | number | Record<string, string | number>>[];
  error?: Error | string;
};

type BaseChain = {
  _table: string;
  auth: {
    session: () => { user?: { email: string; userId: string } };
  };
  contains: () => BaseChain;
  eq: () => BaseChain;
  filter: () => BaseChain;
  insert: () => BaseChain;
  in: () => BaseChain;
  is: () => BaseChain;
  select: () => BaseChain;
} & Promise<QueryResult>;

type MOCK_DATA = Record<string, QueryResult["data"]> & {
  accounts: Account[];
  leads: Lead[];
  sources: Source[];
  transactions: Transaction[];
};

export function getMockData(): MOCK_DATA {
  const baseMockData = {
    accounts: [],
    leads: [],
    sources: [],
    transactions: [],
  };

  try {
    const storedMockData = JSON.parse(
      localStorage.getItem("mockData") as string
    );
    return { ...baseMockData, ...storedMockData } as MOCK_DATA;
  } catch (e) {
    return baseMockData;
  }
}

function setMockData(newMockData: Partial<MOCK_DATA>) {
  const mockData = getMockData();

  for (let [key, newValues] of Object.entries(newMockData)) {
    if (!newValues) {
      continue;
    }

    newValues = (newValues as Record<string, unknown>[]).map((nv, i) => ({
      ...nv,
      id: i + mockData[key].length,
    }));

    mockData[key] = [...mockData[key], ...newValues];
  }

  localStorage.setItem("mockData", JSON.stringify(mockData));
}

export default function getMockSupabaseClient(): SupabaseClient {
  const client = {
    auth: {
      session() {
        return {
          user: {
            email: "brock@bridgetowncollective.com",
            userId: "123",
          },
        };
      },
      signOut() {
        localStorage.removeItem("mockData");
        window.location.reload();
      },
    },
    from(table: string): BaseChain {
      let insertLength = 0;
      const filterParams: Record<string, string[]> = {};

      const chain: Partial<BaseChain> = new Promise((res) => {
        setTimeout(() => {
          const mockData = getMockData();
          if (Object.keys(mockData).includes(table)) {
            let data = mockData[table].slice(insertLength * -1);

            if (Object.keys(filterParams).length > 0) {
              Object.keys(filterParams).forEach((key) => {
                data = data.filter((d) =>
                  filterParams[key].includes(d[key] as string)
                );
              });
            }

            res({ data });
          }
          res({ data: [], error: new Error("Invalid table name") });
        }, Math.random() * 1000);
      });

      chain.contains = () => chain as BaseChain;
      chain.eq = () => chain as BaseChain;
      chain.filter = () => chain as BaseChain;
      chain.in = (...args) => {
        filterParams[args.shift() as unknown as string] =
          args.shift() as unknown as string[];
        return chain as BaseChain;
      };
      chain.is = () => chain as BaseChain;
      chain.select = () => chain as BaseChain;

      chain.insert = (...args) => {
        const input = args.shift();
        const newEntries = (Array.isArray(input)
          ? input
          : [input]) as unknown as QueryResult["data"];
        insertLength = Array.isArray(newEntries) ? newEntries.length : 1;
        if (insertLength > 0) {
          setMockData({
            [table]: newEntries,
          });
        }
        return chain as BaseChain;
      };

      return chain as BaseChain;
    },
  };

  return client as unknown as SupabaseClient;
}
