import Link from "next/link";
import { Divider, Link as UILink, Spacer } from "@nextui-org/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCurrentAccount } from "../lib/accounts";
import getSupabaseClient, { useAuthSession } from "../lib/supabase";
import Image from "next/image";

const sideNavRoutes: Record<string, string> = {
  "/lead-analysis": "Lead Analysis",
  "/customer-analysis": "Customer Analysis",
};

function logout() {
  void getSupabaseClient().auth.signOut();
  window.location.reload();
}

export default function SideNav() {
  const account = useCurrentAccount();
  const { email, userId } = useAuthSession();
  const router = useRouter();

  useEffect(() => {
    if (email && ["/login", "/"].includes(router.pathname)) {
      router.push(account?.id ? "/lead-sources" : "/accounts");
    }
  }, [account, email, router]);

  const curAuthRole = account?.userRoles[userId as string] || "admin";

  const isNewAccount =
    account?.createdAt &&
    (new Date(account.createdAt).getTime() || 0) >
      Date.now() - 7 * 24 * 60 * 60 * 1000;

  return (
    <div
      className={`p-4 flex flex-col text-left items-start w-60 border-r-indigo-400 h-screen sticky top-0 ${
        isNewAccount ? "pt-16" : ""
      }`}
      style={{ borderRight: "solid 1px #d3d3d3" }}
    >
      <Image src="/LIP-logo-wide-2.png" alt="" width="60px" height="30px" />

      {email && account?.name ? (
        <div style={{ width: "100%" }}>
          <Spacer />
          <Link href="/customer-analysis">
            <UILink color="primary" underline>
              <p className="text-lg">{account.name}</p>
            </UILink>
          </Link>
          <Spacer />
          <Divider />
        </div>
      ) : (
        <div></div>
      )}

      <Spacer y={2} />

      <div className="w-full">
        {account?.name
          ? Object.entries(sideNavRoutes).map(([path, label]) => (
              <div key={path} className="w-full">
                <Link href={path} key={path}>
                  <UILink
                    color={
                      router.pathname.endsWith(path) ? "primary" : "inherit"
                    }
                    underline
                    className="w-full"
                  >
                    <p className="text-xl w-full">{label}</p>
                  </UILink>
                </Link>
                <Spacer />
              </div>
            ))
          : null}
      </div>

      <div className="flex-grow"></div>

      {curAuthRole === "viewer" ? null : (
        <div>
          <Spacer />
          <UILink underline onClick={() => router.push("/lead-sources")}>
            Lead Sources
          </UILink>
          <UILink underline onClick={() => router.push("/revenue-sources")}>
            Revenue Sources
          </UILink>
          <Spacer />
          <Divider />
        </div>
      )}

      <div className="flex flex-col items-start">
        <Spacer />
        {curAuthRole === "viewer" ? null : (
          <UILink underline onClick={() => router.push("/account")}>
            Manage Account
          </UILink>
        )}
        <UILink underline onClick={() => router.push("/accounts")}>
          My Accounts
        </UILink>
        <UILink
          underline
          onClick={() =>
            window.open("https://leadinsightpro.tawk.help/", "_blank")
          }
        >
          Help Docs
        </UILink>
        <UILink underline onClick={() => logout()}>
          Logout
        </UILink>
      </div>
    </div>
  );
}
