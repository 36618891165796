import { Spacer } from "@nextui-org/react";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

import getSupabaseClient from "../lib/supabase";
import Form, { FormValues } from "./form";

function Login() {
  const [didSend, setDidSend] = useState<boolean>(false);

  async function doLogin({ email, password }: FormValues) {
    const { error } = await getSupabaseClient().auth.signIn(
      {
        email,
        password,
      },
      { redirectTo: window.location.origin }
    );
    if (error) {
      throw new Error(error.message);
    }

    setDidSend(true);
    setTimeout(() => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "login",
        userId: btoa(email),
      });
      window.location.reload();
    }, 1000);
  }

  if (didSend) {
    return (
      <div className="max-w-4xl mx-auto text-center">
        <p className="text-3xl">
          Logging in - you&#39;ll be redirected shortly
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto relative">
      <div
        className="absolute -left-48 top-10 w-48 h-12 shadow-xl"
        style={{ backgroundColor: "#EBF4FF" }}
      ></div>
      <div
        className="absolute -left-36 top-28 w-48 h-12 shadow-xl"
        style={{ backgroundColor: "#001D3D" }}
      ></div>
      <div
        className="absolute -right-48 bottom-10 w-48 h-12 shadow-xl"
        style={{ backgroundColor: "#FFA62B" }}
      ></div>
      <div
        className="absolute -right-36 bottom-28 w-48 h-12 shadow-xl"
        style={{ backgroundColor: "#FDE74C" }}
      ></div>

      <div
        className="text-center rounded-xl p-8 bg-white shadow-lg relative"
        style={{ border: "solid 1px #999" }}
      >
        <Image src="/LIP-logo-wide-2.png" alt="" width="140px" height="70px" />
        <p className="text-4xl mb-2 font-bold">Welcome Back</p>
        <p className="italic">Login & see your revenue!</p>
        <Spacer />

        <div className="text-left">
          <Form
            fields={[
              {
                name: "email",
                type: "email",
                inline: true,
                size: "lg",
                width: "100%",
                required: true,
                label: "Email",
              },
              {
                name: "password",
                type: "password",
                inline: true,
                size: "lg",
                width: "100%",
                required: true,
                label: "Password",
              },
            ]}
            submitFunc={doLogin}
            opts={{ submitLabel: "Login" }}
          />
        </div>

        <Spacer y={2} />
        <p className="text-md">
          Don&#39;t have an account?
          <Link href="/register">
            <span className="ml-2 cursor-pointer text-blue-500">
              Register here
            </span>
          </Link>
        </p>
        <p>
          Can&#39;t login?
          <Link href="/reset">
            <span className="ml-2 cursor-pointer text-blue-500">
              Reset Password
            </span>
          </Link>
          ?
        </p>
      </div>
    </div>
  );
}

export default Login;
