import getSupabaseClient from "./supabase";

export type Lead = {
  createdAt: string;
  email: string;
  extra?: Record<string, unknown>;
  id: number;
  sourceId: number;
};

export async function getLeadsForSources(
  sourceIds: number[],
  fromServer = false
): Promise<Record<number, Lead[]>> {
  const paginatedSets: Lead[][] = [];
  let hasMore = true;

  while (hasMore) {
    let query = getSupabaseClient(fromServer)
      .from("leads")
      .select("*")
      .order("id", { ascending: true })
      .in("source_id", sourceIds);

    const lastSet = paginatedSets.slice().pop();
    if (lastSet && lastSet.length) {
      query = query.gt("id", lastSet[lastSet.length - 1].id);
    }

    const { data: leads } = await query;

    const parsedLeads: Lead[] = (leads || []).map((l) => ({
      createdAt: l.created_at,
      accountId: l.account_id,
      email: l.email,
      extra: l.extra,
      id: l.id,
      sourceId: l.source_id,
    }));

    paginatedSets.push(parsedLeads);

    hasMore = parsedLeads.length === 10000 && paginatedSets.length < 100;
  }

  return paginatedSets
    .reduce((a, b) => a.concat(b), [])
    .reduce(
      (a: Record<number, Lead[]>, b: Lead) => ({
        ...a,
        [b.sourceId]: [...(a[b.sourceId] || []), b],
      }),
      {}
    );
}

export async function addLeads(
  leads: Omit<Lead, "id">[],
  fromServer = false
): Promise<number[]> {
  const allIds: number[] = [];

  for (let i = 0; i < leads.length; i += 999) {
    const { data, error } = await getSupabaseClient(fromServer)
      .from("leads")
      .insert(
        leads.slice(i, i + 9999).map((lead) => ({
          created_at: lead.createdAt,
          email: lead.email,
          extra: lead.extra || {},
          source_id: lead.sourceId,
        }))
      )
      .select("id");

    if (error) {
      console.error(error);
      return [];
    }

    if (!data || !data.length || !data[0].id) {
      return [];
    }

    allIds.push(...data.map((d) => d.id));
  }

  return allIds;
}
